define(['jquery', '$console'], function($, $console) {

  
  /*
  Usage example:
  this function at the moment is designed to make only one element on a page sticky..
  I am sure this can be extended for multiple stickies later..But why??

  // setup stickyContent using config object

  var stickyConfig = {
      stickyDivId: ".facets-panel.js-facet-panel" ,
      changeableDivIds: ".js-facets-wrapper, .cookie-message.cookie-message-skin-default"
   };

  // init/start stickyContent function
  stickyContent.init(stickyConfig); // must call this function , or else nothing will happen
  */

  var sc = {

    sDivId: '',
    changeableDivIds: 0,
    opts: {},

    init: function(cfg) {

      if (cfg) {
        sc.opts.parent = (cfg.parentDivId) ? cfg.parentDivId : '';
        sc.opts.inner_scrolling = (cfg.inner_scrolling) ? cfg.inner_scrolling : true;
        sc.opts.offset_top = (cfg.offsetTop) ? cfg.offsetTop : 0;

        sc.sDivId = (cfg.stickyDivId) ? cfg.stickyDivId : '';
        sc.changeableDivIds = (cfg.changeableDivIds) ? cfg.changeableDivIds : 0;
      }

      if ($(sc.sDivId).length) {

        $(sc.sDivId).stick_in_parent(sc.opts);
        // listener for changeable divs
        if (sc.changeableDivIds !== 0 && $(sc.changeableDivIds).length) {

          $(sc.changeableDivIds).on('click', function(e) {
            // need this time out for ajax facets to  finish its ajax calls..
            setTimeout(function() {
              $console.log('changeableDivIds: Clicked' + e.target);
              $(sc.sDivId).stick_in_parent(sc.opts);
            }, 1000);
          });
        }

      }
    },
  };
  return sc;
});


